import { ReactChildren } from "../../../services/model/types";
import { Text } from "@mantine/core";

type InputProps = {
  color: string;
  textColor: string;
  className?: string;
  textSize?: number | string;
  size?: number | string;
  padding?: number | string;
};

export function MyBadge(props: ReactChildren & InputProps) {
  const { children, color, textColor, className, textSize, size } = props;
  return (
    <div className={className} style={{ zIndex: 1 }}>
      <div
        style={{
          backgroundColor: color,
        }}
        className="flex justify-center items-center px-3 py-1 rounded-full"
      >
        <Text
          color={textColor}
          // @ts-ignore
          size={textSize}
          className="uppercase"
          weight={600}
        >
          {children}
        </Text>
      </div>
    </div>
  );
}
