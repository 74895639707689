import { Container } from "../../../UI/container/Container";
import { useColors } from "../../../../hooks/colors/useColors";
import {
  IconClock2,
  IconDeviceMobile,
  IconMail,
  IconMapPin,
  IconMessage,
  IconPhone,
} from "@tabler/icons";
import { Grid, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Image from "next/image";

export function Address(props: { hideIFrame?: boolean }) {
  const { colors } = useColors();
  const { t } = useTranslation("common");
  return (
    <div style={{ backgroundColor: colors.background }} className="w-full p-0 ">
      <Container noHeight>
        <div className="w-full">
          <Grid className="w-full justify-center">
            <Grid.Col
              span={{ base: 12, md: 6, lg: 3 }}
              className="flex gap-4 justify-center sm:my-8 lg:my-0"
            >
              <div className="flex">
                <Text size="lg" className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <IconMapPin
                      color={colors.primary}
                      size={30}
                      style={{ alignSelf: "center" }}
                    />
                    <b>{t("address")}</b>
                  </div>
                  <span>Estrada da Luz, nº90, 10ºE</span>
                  <span>Edifício Atlanta Park I</span>
                  <span>1600-160 Lisboa</span>
                </Text>
              </div>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 6, lg: 3 }}
              className="flex items-center gap-4 justify-center !sm:my-4 "
            >
              <Text size="lg" className="flex flex-col gap-2 justify-center">
                <div className="flex gap-2">
                  <IconClock2 color={colors.primary} size={30} />
                  <b>{t("ops-hours")}</b>
                </div>
                <span
                  // @ts-ignore
                  dangerouslySetInnerHTML={{ __html: t("hours") }}
                />
              </Text>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 6, lg: 3 }}
              className="flex justify-center "
            >
              <Text size="lg" className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <IconMessage color={colors.primary} size={30} />
                  <b>{t("contacts")}</b>
                </div>
                <a href="tel:211 929 428" className="flex items-center gap-2">
                  <IconPhone color={colors.primary} size={30} />
                  211 929 428
                </a>
                <a href="tel:910646434" className="flex items-center gap-2">
                  <IconDeviceMobile color={colors.primary} size={30} />
                  910 646 434
                </a>
                <a
                  href="mailto:geral@psiworks.pt"
                  className="flex items-center gap-2"
                >
                  <IconMail color={colors.primary} size={30} />
                  geral@psiworks.pt
                </a>
              </Text>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 6, lg: 3 }}
              className="flex justify-center "
            >
              <a
                className="h-[211px]"
                target="_blank"
                href="https://www.google.com/maps/dir//Psiworks,+Estrada+da+Luz,+90+3%C2%BA+andar+-+Letra+A,+1600-160+Lisboa/@38.7503723,-9.1748335,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0xd19338e6732072f:0x520b6ab7cd391a3a!2m2!1d-9.1726448!2d38.7503723"
                rel="noreferrer"
              >
                <Image
                  src="/img/psiworks-map.webp"
                  height={211}
                  width={256}
                  alt="Address map"
                />
              </a>
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
