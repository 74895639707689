import { ReactNode } from "react";

type InputType = {
  children: ReactNode;
  className?: string;
  size?: number | string;
  color?: string;
  centerText?: boolean;
};

export function MyH1(props: InputType) {
  const { children, className, size, color, centerText } = props;
  return (
    <h1
      className={className}
      style={{
        fontSize: size,
        color: color,
        margin: 0,
        textAlign: centerText ? "center" : undefined,
      }}
    >
      {children}
    </h1>
  );
}
