import { Card, Divider, Grid, Text } from "@mantine/core";
import { useColors } from "../../../../hooks/colors/useColors";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { ButtonLink } from "../../../UI/button-link/ButtonLink";
import { isPhone } from "../../../../constants/env";
import { isTablet } from "react-device-detect";
import { Post } from "../../../../types/graphql";
import Link from "next/link";
import { MyBadge } from "../../../UI/my-badge/MyBadge";

type InputProps = {
  data: Post;
};

export function BlogCard({ data }: InputProps) {
  const { featuredImage, title, excerpt, categories } = data;

  const { colors } = useColors();
  const { t } = useTranslation("common");
  const category = categories?.nodes.filter(
    (cat) =>
      cat.name !== null &&
      typeof cat.name !== "undefined" &&
      !cat.name.toString().includes("blog-")
  )[0];

  let bodyClass = "flex flex-col justify-between h-[400px] mt-4";
  if (isPhone) bodyClass = "flex flex-col justify-between";

  function width() {
    if (isPhone) {
      return "96vw";
    } else if (isTablet) {
      return 360;
    } else {
      return "100%";
    }
  }

  return (
    <Grid.Col
      span={{ base: 12, md: 6, lg: 4 }}
      className="flex justify-center !p-0"
    >
      <Card
        shadow="sm"
        p="lg"
        radius="md"
        withBorder
        className="!p-0 m-4 hover:shadow-2xl"
        style={{
          width: width(),
        }}
      >
        {category && (
          <Link href={`/blog/categoria/${category.name}`}>
            <MyBadge
              size="auto"
              className="absolute top-2 right-2"
              color={colors.primary}
              textColor="white"
              textSize="0.8rem"
            >
              {category.name}
            </MyBadge>
          </Link>
        )}
        <div
          className="relative max-h-[250px]"
          style={{ aspectRatio: "75/40" }}
        >
          {featuredImage && title && (
            <Image
              src={featuredImage?.node.sourceUrl!}
              layout="fill"
              alt={title}
              objectFit="cover"
            />
          )}
        </div>
        <div className={bodyClass}>
          <div className="flex flex-col justify-center items-center h-full">
            <h2
              className="text-center font-bold p-2 lg:text-3xl !my-2"
              style={{ color: colors.primary }}
            >
              {title}
            </h2>
            <Text size="md" c="dimmed" className="text-center !p-2">
              {excerpt && (
                <i
                  className="lg:text-lg"
                  dangerouslySetInnerHTML={{ __html: excerpt }}
                />
              )}
            </Text>
          </div>
          <div className="flex items-center justify-center w-full mb-4">
            <ButtonLink
              href={`/blog/${data.slug}`}
              color={colors.white}
              textColor={colors.secondary}
              title={t("blogReadArticle")}
              uppercase
            />
          </div>
        </div>
        <Divider />
        <div className="h-[36px] flex justify-center items-center">
          <Link href={`/blog/autor/${data.author?.node.slug}`}>
            <Text
              className="!text-center uppercase !text-[12px] !text-blue-700 dark:!text-blue-400"
              c={colors.grayText}
            >
              {data.author ? data.author.node.name! : ""}
            </Text>
          </Link>
        </div>
      </Card>
    </Grid.Col>
  );
}
